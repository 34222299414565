import { memo, useState } from "react";
import { Navigate } from 'react-router-dom';
import { FormElemTypes } from '../../components/Form/FormElemTypes';
import Form from '../../components/Form/Form';
import monitoringIcon from "../../assets/images/monitoring-icon-180x180.png";
import { MdPerson, MdLock, MdError, MdOutlineRefresh } from 'react-icons/md';
import { useAuthenticateMutation } from '../../services/UserService';
import IAuthenticate from '../../models/requests/IAuthenticate';
import { useAppSelector } from "../../hooks/redux";
import { fetchDataAlert, history } from "../../utils/utils";
import { useForm } from "react-hook-form";
import { useGetCaptchaMutation } from "../../services/CaptchaService";
import { useDispatch } from "react-redux";
import { removeCaptchaData, setCaptchaImg, setIsCaptcha } from "../../store/reducers/CaptchaReducer";

function LoginPage() {
    const [ authenticate, { isLoading } ] = useAuthenticateMutation();
    const [errorMsg, setErrorMsg] = useState<string>();
    const currentUser = useAppSelector(state => state.user.currentUserState);
    const captchaImg = useAppSelector(state => state.captcha.captchaImg);
    const isCaptcha = useAppSelector(state => state.captcha.isCaptcha)
    const [ captchaQuery ] = useGetCaptchaMutation();
    
    const dispatch = useDispatch();
    const methods = useForm();

    async function handleGenerateCaptcha() {
        
        const result: any = await captchaQuery();

        fetchDataAlert({
            result: result,
            onError: (msg: string) => setErrorMsg(msg),
            showErrorMessage: false,
            showException: true
        });

        dispatch(setCaptchaImg( result?.data?.data ));        
    }
    
    async function handleAuthenticate(data: IAuthenticate) {
        const result = await authenticate(data);

        fetchDataAlert({
            result: result,
            onError: (msg: string) => setErrorMsg(msg),
            showErrorMessage: false,
            showException: true
        });

        let captchaImg: string | undefined;

        if ("data" in result && result?.data?.data?.captcha) {
            captchaImg = result?.data.data.captcha;
            dispatch(setCaptchaImg(captchaImg));
        }        
        
        dispatch(setIsCaptcha(captchaImg === undefined));

        methods.reset({
            captchaField: ""
        });
    }


    let prevPath: string =
        history.location?.state ??
        history.location?.pathname ??
        "/";

    if (['/login', '/registration'].includes(prevPath.toLowerCase())) {
        prevPath = "/";
    }

    if (currentUser) {
        dispatch(removeCaptchaData());
        return <Navigate to={prevPath} />
    } else {
        return (
            <div className='login-page'>
                <Form
                    className="authorization-form"
                    header={<div className='authorization-form-header'>
                        <img
                            src={monitoringIcon}
                            alt="monitoring-icon"
                            className='authorization-form-icon'
                        />
                        <div className="authorization-form-title">
                            СЕРВИС МОНИТОРИНГА
                        </div>
                    </div>}
                    elements={[
                        {
                            type: FormElemTypes.text,
                            name: "username",
                            icon: <MdPerson />,
                            htmlAttributes: {
                                placeholder: "Логин",
                                required: true
                            }
                        },
                        {
                            type: FormElemTypes.password,
                            name: "password",
                            icon: <MdLock />,
                            htmlAttributes: {
                                placeholder: "Пароль",
                                required: true
                            }
                        },
                        {
                            type: FormElemTypes.checkbox,
                            name: "isLDAPAuthenticate",
                            label: "Доменная аутентификация"
                        },
                        {
                            type: FormElemTypes.captcha,
                            name: 'captchaField',
                            icon: <MdOutlineRefresh />,
                            isHidden: isCaptcha,
                            img_base64: captchaImg,
                            onClick: handleGenerateCaptcha
                        }
                    ]}
                    submitButton={{
                        name: "Войти",
                        isLoading: isLoading,
                        isDisabled: isLoading,
                        handleClick: (data: IAuthenticate) => { handleAuthenticate(data) }
                    }}
                    isError={!!errorMsg}
                    errorMsg={errorMsg && !isLoading ?
                        <div className='form-error-msg text-danger d-flex-center-unset small'>
                            <MdError className='me-1' />
                            {errorMsg}
                        </div> :
                        <div className="opacity-0 pointer-events-none">
                            <MdError />
                        </div>}
                />
            </div>
        )
    }
}

export default memo(LoginPage);
