import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { defaultBaseQueryParams } from "../constants/defaultBaseQueryParams";
import { IDefaultDataResponse } from "../models/responses/IDefaultResponse";
import {TGraphsData} from "../models/responses/TGraphsData";
import {IGetErrorsByComplex} from "../models/responses/IGetErrorsByComplex";
import {RSwal} from "../constants/sweetAlert";
import {IHealthCheckErrorsFilters} from "../models/requests/IHealthCheckErrorsFilters";
import {IHealthCheckBriefInfo} from "../models/responses/IHealthCheckBriefInfo";
import {IHealthCheckError} from "../models/entities/IHealthCheckError";
import IErrorCountByComplex from "../models/responses/IErrorCountByComplex";
export const healthCheckApi = createApi({
    reducerPath: 'healthCheckApi',
    baseQuery: fetchBaseQuery(defaultBaseQueryParams),
    endpoints: build => ({
        getHealthCheckErrors: build.query<IDefaultDataResponse<IHealthCheckBriefInfo[]>, IHealthCheckErrorsFilters>({
            query: (filters)  => ({
                url: '/HealthCheck/GetHealthCheckErrors',
                method: "POST",
                body: filters
            }),
            // Only have one cache entry because the arg always maps to one string
            serializeQueryArgs: ({ endpointName, queryArgs }) => {
                return endpointName
            },
            // Always merge incoming data to the cache entry
            merge: (currentCache, newItems, other) => {
                if (other.arg.lastId) {
                    currentCache.data.push(...newItems.data)
                }
                else {
                    currentCache.data = newItems.data
                }
            },
            // Refetch when the page arg changes
            forceRefetch({ previousArg, currentArg }) {
                return JSON.stringify(currentArg) !== JSON.stringify(previousArg)
            },
            transformResponse: (errors: IDefaultDataResponse<IHealthCheckBriefInfo[]>) => {
                if(errors?.data && errors.data.length === 0) {
                    RSwal.fire({
                        icon: 'info',
                        title: 'Таких ошибок больше не найдено. Увеличьте период отбора в фильтрах (по умолчанию за последние 5 дней) и попробуйте снова'
                    })
                }
                return errors;
            }
        }),
        getStatisticHealthCheckComplexes: build.query<IDefaultDataResponse<IErrorCountByComplex[]>, void>({
            query: () => ({
                url: '/HealthCheck/GetHealthCheckComplexes'
            })
        }),
        getHealthCheckError: build.query<IDefaultDataResponse<IHealthCheckError>, number>({
            query: (id)  => ({
                url: `/HealthCheck/GetHealthCheckError/${id}`,
                method: "GET"
            }),
            transformResponse: (res: IDefaultDataResponse<IHealthCheckError>) => {
                if(res.success) {
                    res.data.datemsk = res.data.datemsk.split('.')[0].replace('T', ' ');
                    // res.data.dater = res.data.dater.split('.')[0].replace('T', ' ');
                }

                return res;
            }
        }),
        getHealthCheckErrorsCount: build.query<IDefaultDataResponse<number>, number | undefined>({
            query: (hours)  => ({
                url: `/HealthCheck/GetHealthCheckErrorsCount${hours ? `?hours=${hours}` : ''}`,
                method: "GET"
            })
        }),
        getHealthCheckErrorsByComplex: build.query<IDefaultDataResponse<IGetErrorsByComplex>, number | void>({
            query: (errorType)  => ({
                url: `/HealthCheck/GetHealthCheckErrorsByComplex`
            })
        }),
        getHealthCheckGraphsDataByComplexes: build.query<IDefaultDataResponse<TGraphsData>, void>({
            query: () => ({
                url: '/HealthCheck/GetHealthCheckGraphsDataByComplexes'
            })
        }),
        getHealthCheckGraphsDataByRegions: build.query<IDefaultDataResponse<TGraphsData>, void>({
            query: () => ({
                url: '/HealthCheck/GetHealthCheckGraphsDataByRegions'
            })
        }),
    })
})

export const {
    useLazyGetHealthCheckErrorsQuery,
    useGetStatisticHealthCheckComplexesQuery,
    useGetHealthCheckErrorQuery,
    useGetHealthCheckErrorsCountQuery,
    useGetHealthCheckErrorsByComplexQuery,
    useLazyGetHealthCheckGraphsDataByComplexesQuery,
    useLazyGetHealthCheckGraphsDataByRegionsQuery
} = healthCheckApi;