import React, { memo, useEffect, useRef, useState, useMemo } from 'react'
import { Splide } from '@splidejs/react-splide';
import { MdDns, MdErrorOutline, MdOutlineReport, MdSettingsSystemDaydream, MdWarningAmber } from 'react-icons/md';
import IErrorCountByComplex from '../../../models/responses/IErrorCountByComplex';
import { StatisticsLineItem } from './StatisticsLineItem';
import RsuiteTable, { ColumnsList } from '../../../components/rsuite-table/RsuiteTable';
import { ComplexProperties, ComplexPropertiesNames } from '../../../models/entities/IComplex';
import { IGetErrorsByComplex } from '../../../models/responses/IGetErrorsByComplex';
import { getDateOfStartLastDay, history, nameof } from '../../../utils/utils';
import IErrorCountByComplexType from '../../../models/responses/IErrorCountByComplexType';
import { MonitoringPanelLocState } from '../../monitoring-page/MonitoringPanel/MonitoringPanel';
import { EErrorTypes } from '../../../models/entities/EErrorTypes';
import { useAppSelector } from '../../../hooks/redux';
import {useLocation} from "react-router-dom";

type StatisticsLineProps = {
    complexes: IErrorCountByComplex[] | undefined,
    complexesIsLoading: boolean,

    errorsCount: number | undefined,
    errorsCountIsLoading: boolean,

    errorsCountOfLastDay: number | undefined,
    errorsCountOfLastDayIsLoading: boolean,

    firingAlerts?: number | undefined,
    firingAlertsIsLoading?: boolean,

    errorsByComplex: IGetErrorsByComplex | undefined
    errorsByComplexIsLoading: boolean,

    criticalErrorsByComplex?: IGetErrorsByComplex | undefined
    criticalErrorsByComplexIsLoading?: boolean,
}

const errorsByComplexTypeColumns: ColumnsList = [
    {
        columnProps: {
            flexGrow: 1,
            fullText: true
        },
        headerProps: {
            children: ''
        },
        cellProps: {
            dataKey: nameof<IErrorCountByComplexType>('complex_type')
        }
    },
    {
        columnProps: {
            flexGrow: 1,
            fullText: true
        },
        headerProps: {
            children: ''
        },
        cellProps: {
            dataKey: nameof<IErrorCountByComplexType>('count')
        }
    }
];

const complexesColumns: ColumnsList = [
    {
        columnProps: {
            sortable: true,
            flexGrow: 1,
            fullText: true
        },
        headerProps: {
            children: ComplexPropertiesNames.region
        },
        cellProps: {
            dataKey: ComplexProperties.region
        }
    },
    {
        columnProps: {
            sortable: true,
            flexGrow: 1,
            fullText: true
        },
        headerProps: {
            children: ComplexPropertiesNames.type
        },
        cellProps: {
            dataKey: nameof<IErrorCountByComplexType>('complex_type')
        }
    }
];

const StatisticsLine = memo((props: StatisticsLineProps) => {
    const currentUser = useAppSelector(state => state.user.currentUserState);

    const [itemsPerPage, setItemsPerPage] = useState<number>(getCurrentItemsPerPage());
    
    const location = useLocation();
    
    const redirectRouter = location.pathname === "/HealthCheck" ? "/MonitoringHealthCheck" : "/Monitoring";

    useEffect(() => {
        window.addEventListener('resize', () => handleResize());
        return window.removeEventListener('resize', () => handleResize());
    }, []);

    function handleResize() {
        const currentItemPerPage = getCurrentItemsPerPage();
        setItemsPerPage(currentItemPerPage);
    }

    function getCurrentItemsPerPage(): number {
        if (window.innerWidth > 1200) {
            return 4;
        }
        if (window.innerWidth > 820) {
            return 3;
        }
        if (window.innerWidth > 550) {
            return 2;
        }
        return 1;
    }

    const criticalErrorsCount = useMemo(() => {
        return props.criticalErrorsByComplex?.errorsByComplexType.reduce((acc, item) => {
            acc += item.count;
            return acc;
        }, 0)
    }, [props.criticalErrorsByComplex])

    return (
        <div className="statistics-line">
            <Splide
                className='w-100 h-100'
                options={{
                    autoplay: true,
                    interval: 5000,
                    rewind: true,
                    perPage: itemsPerPage,
                    perMove: 1,
                    gap: '1rem',
                    arrows: false
                }}
            >
                <StatisticsLineItem
                    icon={<MdSettingsSystemDaydream />}
                    name="Количество систем"
                    value={props.complexes?.length}
                    isLoading={props.complexesIsLoading}
                    DropDownMenu={() =>
                            <div
                                className="splide-dropdown-menu"
                                style={{
                                    height:
                                        props.complexes?.length! * 35 < 217 ?
                                            props.complexes?.length! * 35 : ''
                                }}
                            >
                                <RsuiteTable
                                    data={props.complexes}
                                    columns={complexesColumns}
                                    showHeader={false}
                                    virtualized={true}
                                    onRowClick={(row: IErrorCountByComplex) => {
                                        const locState: MonitoringPanelLocState = {
                                            regions: [row.region_id],
                                            complexTypes: [row.complex_type_id]
                                        }
                                        if (history.navigate) {
                                            history.navigate(
                                                redirectRouter,
                                                {
                                                    state: locState
                                                }
                                            )
                                        }
                                    }}
                                />
                            </div>
                    }
                />
                <StatisticsLineItem
                    icon={<MdErrorOutline className='error' />}
                    name="Количество ошибок"
                    value={props.errorsCount}
                    isLoading={props.errorsCountIsLoading}
                />
                <StatisticsLineItem
                    icon={<MdWarningAmber className='error' />}
                    name="Ошибок за 24 часа"
                    value={props.errorsCountOfLastDay}
                    isLoading={props.errorsCountOfLastDayIsLoading}
                    DropDownMenu={() =>
                        !props.errorsByComplexIsLoading ?
                            <div
                                className="splide-dropdown-menu"
                                style={{
                                    height:
                                        props.errorsByComplex?.errorsByComplexType.length! * 35 < 217 ?
                                            props.errorsByComplex?.errorsByComplexType.length! * 35 : ''
                                }}
                            >
                                <RsuiteTable
                                    data={props.errorsByComplex?.errorsByComplexType}
                                    columns={errorsByComplexTypeColumns}
                                    showHeader={false}
                                    virtualized={true}
                                    onRowClick={(row: IErrorCountByComplex) => {
                                        const locState: MonitoringPanelLocState = {
                                            complexTypes: [row.complex_type_id],
                                            startDateTypeDate: getDateOfStartLastDay()
                                        }
                                        if (history.navigate) {
                                            history.navigate(
                                                redirectRouter,
                                                {
                                                    state: locState
                                                }
                                            )
                                        }
                                    }}
                                />
                            </div> : <></>
                    }
                />
                
                {(currentUser?.role?.isAdmin || currentUser?.role?.isSuperAdmin) && props?.criticalErrorsByComplex &&
                    <StatisticsLineItem
                        icon={<MdDns />}
                        name="Серверных проблем"
                        value={props.firingAlerts}
                        isLoading={props.firingAlertsIsLoading ?? false}
                        link={{ to: '/ServerLoad' }}
                        title='Перейти на страницу нагрузок серверов'
                    />}

                {props?.criticalErrorsByComplex &&
                    <StatisticsLineItem
                        icon={<MdOutlineReport className='error' />}
                        name="Критических ошибок"
                        value={criticalErrorsCount}
                        isLoading={props.criticalErrorsByComplexIsLoading ?? false}
                        DropDownMenu={() =>
                            !props.criticalErrorsByComplexIsLoading ?
                                <div
                                    className="splide-dropdown-menu"
                                    style={{
                                        height:
                                            props.criticalErrorsByComplex?.errorsByComplexType.length! * 35 < 217 ?
                                                props.criticalErrorsByComplex?.errorsByComplexType.length! * 35 : ''
                                    }}
                                >
                                    <RsuiteTable
                                        data={props.criticalErrorsByComplex?.errorsByComplexType}
                                        columns={errorsByComplexTypeColumns}
                                        showHeader={false}
                                        virtualized={true}
                                        onRowClick={(row: IErrorCountByComplex) => {
                                            const locState: MonitoringPanelLocState = {
                                                complexTypes: [row.complex_type_id],
                                                errorLevels: [EErrorTypes['Критическая']],
                                                startDateTypeDate: getDateOfStartLastDay()
                                            }
                                            if (history.navigate) {
                                                history.navigate(
                                                    '/Monitoring',
                                                    {
                                                        state: locState
                                                    }
                                                )
                                            }
                                        }}
                                    />
                                </div> : <></>
                        }
                    />}
            </Splide>
        </div>
    )
});

export default StatisticsLine