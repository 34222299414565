import React, { memo, useLayoutEffect, useMemo, useRef, useState } from "react";
import PageTitle from "../../components/PageTitle";
import StatisticsLine from "../home-page/StatisticsLine/StatisticsLine";
import { useAppSelector } from "../../hooks/redux";
import { defaultPollingInterval } from "../../store/globalVariables";
import useDidMountEffect from "../../hooks/skipFirstUseEffectRun";
import {
    useGetHealthCheckErrorsByComplexQuery,
    useGetHealthCheckErrorsCountQuery, 
    useGetStatisticHealthCheckComplexesQuery,
    useLazyGetHealthCheckGraphsDataByComplexesQuery,
    useLazyGetHealthCheckGraphsDataByRegionsQuery
} from "../../services/HealthCheckService";
import ErrorsCountTable from "../home-page/ErrorsCountTable";
import GraphsContainer from "../home-page/Graphs/GraphsContainer";

function HealthCheckPage() {
    const currentUser = useAppSelector(state=>state.user.currentUserState);

    const pollingInterval = useRef(defaultPollingInterval);

    useDidMountEffect(()=>{
        pollingInterval.current = currentUser?.userSettings?.home_autoupdate_interval! ?? defaultPollingInterval;
    }, [currentUser])

    const {
        data: errorsCount,
        isFetching: errorsCountIsFetching,
        refetch: getErrorsCount,
        fulfilledTimeStamp: getErrorsCountReceivingTime
    } = useGetHealthCheckErrorsCountQuery(undefined, {
        pollingInterval: pollingInterval.current
    });

    const {
        data: errorsCountOfLastDay,
        isFetching: errorsCountOfLastDayIsFetching,
        refetch: getErrorsCountOfLastDay,
        fulfilledTimeStamp: getErrorsCountOfLastDayReceivingTime
    } = useGetHealthCheckErrorsCountQuery(24, {
        pollingInterval: pollingInterval.current
    });

    const {
        data: complexes,
        isFetching: complexesIsFetching,
        refetch: getComplexes,
        fulfilledTimeStamp: getComplexesReceivingTime
    } = useGetStatisticHealthCheckComplexesQuery(undefined, {
        pollingInterval: pollingInterval.current
    });

    const {
        data: errorsByComplex,
        isFetching: errorsByComplexIsFetching,
        isSuccess: errorsByComplexIsSuccess,
        refetch: getErrorsByComplex,
        fulfilledTimeStamp: getErrorsByComplexReceivingTime
    } = useGetHealthCheckErrorsByComplexQuery(undefined, {
        pollingInterval: pollingInterval.current
    });

    const [getGraphsDataByComplexes, {
        data: graphsDataByComplexes,
        isFetching: graphsDataByComplexesIsFetching,
        fulfilledTimeStamp: graphsDataByComplexesReceivingTime
    }] = useLazyGetHealthCheckGraphsDataByComplexesQuery({
        pollingInterval: pollingInterval.current
    });

    const [getGraphsDataByRegions, {
        data: graphsDataByRegions,
        isFetching: graphsDataByRegionsIsFetching,
        fulfilledTimeStamp: graphsDataByRegionsReceivingTime
    }] = useLazyGetHealthCheckGraphsDataByRegionsQuery({
        pollingInterval: pollingInterval.current
    });

    function getStatistic() {
        getErrorsCount();
        getErrorsCountOfLastDay();
        getComplexes();
        getErrorsByComplex();

        if(graphsTypeIsRegions) {
            getGraphsDataByRegions();
        }
        else {
            getGraphsDataByComplexes();
        }
    }

    const isLoading =
        errorsCountIsFetching ||
        errorsCountOfLastDayIsFetching ||
        complexesIsFetching ||
        errorsByComplexIsFetching ||
        graphsDataByComplexesIsFetching;

    const lastReceivingTime = useMemo(()=>{
        if(isLoading) {
            return 0
        }
        else {
            return Math.max(
                getErrorsCountReceivingTime ?? 0,
                getErrorsCountOfLastDayReceivingTime ?? 0,
                getComplexesReceivingTime ?? 0,
                getErrorsByComplexReceivingTime ?? 0,
                graphsDataByComplexesReceivingTime ?? 0
            );
        }
    }, [
        getErrorsCountReceivingTime,
        getErrorsCountOfLastDayReceivingTime,
        getComplexesReceivingTime,
        getErrorsByComplexReceivingTime,
        graphsDataByComplexesReceivingTime
    ])

    const [graphsTypeIsRegions, setGraphsTypeIsRegions] = useState<boolean>(()=>{
        const localGrapthsType = localStorage.getItem('graphsTypeIsRegions');
        if(localGrapthsType === null) {
            return false;
        }
        else {
            try {
                return Boolean(JSON.parse(localGrapthsType))
            }
            catch {
                return false;
            }
        }
    });

    useDidMountEffect(()=>{
        localStorage.setItem('graphsTypeIsRegions', `${graphsTypeIsRegions}`);
    }, [graphsTypeIsRegions])

    useLayoutEffect(()=>{
        if(graphsTypeIsRegions) {
            getGraphsDataByRegions(undefined, true);
        }
        else {
            getGraphsDataByComplexes(undefined, true);
        }
    }, [graphsTypeIsRegions])
    
    
    return (
      <main className="pb-0">
        <PageTitle
            title='HealthCheck системы'
            reloadPage={() => getStatistic()}
            isLoading={isLoading}
            pageUpdateTime={!isLoading ? new Date(lastReceivingTime).toLocaleString().replace(',', "") : ''}
        />
          <div className="home-page">
              <StatisticsLine
                  complexes={complexes?.data}
                  complexesIsLoading={complexesIsFetching}
                  errorsCountIsLoading={errorsCountIsFetching}
                  errorsCount={errorsCount?.data}
                  errorsCountOfLastDay={errorsCountOfLastDay?.data}
                  errorsCountOfLastDayIsLoading={errorsCountOfLastDayIsFetching}
                  errorsByComplex={errorsByComplex?.data}
                  errorsByComplexIsLoading={errorsByComplexIsFetching}
              />
              <div className="home-graphs-and-table-container row mx-0">
                  <div className="col-md-6 col-12 h-md-100 ps-md-0 pe-md-2 px-0 pb-md-3">
                      <GraphsContainer
                          graphsData={graphsTypeIsRegions ? graphsDataByRegions?.data : graphsDataByComplexes?.data}
                          graphsDataIsLoading={graphsTypeIsRegions ? graphsDataByRegionsIsFetching :graphsDataByComplexesIsFetching}
                          errorsByComplexType={errorsByComplex?.data?.errorsByComplexType}
                          graphsTypeIsRegions={graphsTypeIsRegions}
                          setGraphsTypeIsRegions={setGraphsTypeIsRegions}
                      />
                  </div>
                  <div className="col-md-6 col-12 h-md-100 h-50 ps-md-2 pe-md-0 pt-md-0 pt-3 px-0 pb-3">
                      <ErrorsCountTable
                          data={errorsByComplex?.data?.errorsByComplex}
                          isLoading={errorsByComplexIsFetching}
                          isSuccess={errorsByComplexIsSuccess}
                          page="Monitoring"
                      />
                  </div>
              </div>
          </div>
      </main>
    );
}

export default memo(HealthCheckPage);