import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { statisticApi } from "../services/StatisticService";
import { statisticReducer } from "./reducers/StatisticReducer";
import { appApi } from "../services/AppService";
import { userApi } from "../services/UserService";
import { regionApi } from "../services/RegionService";
import { complexApi } from "../services/ComplexService";
import { errorApi } from "../services/ErrorService";
import { issueApi } from "../services/IssueService";
import { productMetricApi } from "../services/ProductMetricService";
import { roleApi } from "../services/RoleService";
import { serverLoadApi } from "../services/ServerLoadService";
import { serverManagerApi } from "../services/ServerManagerService";
import { complexTypeApi } from "../services/ComplexTypeService";
import { userReducer, userSlice } from "./reducers/UserReducer";
import { bugzillaApi } from "../services/BugzillaService";
import { IUser } from "../models/entities/IUser";
import { updateHistoryApi } from "../services/UpdateHistoryService";
import { captchaApi } from "../services/CaptchaService";
import { captchaReducer } from "./reducers/CaptchaReducer";
import { healthCheckApi } from "../services/HealthCheckService";

const allApi = [
    userApi,
    statisticApi,
    appApi,
    bugzillaApi,
    regionApi,
    complexApi,
    complexTypeApi,
    errorApi,
    issueApi,
    productMetricApi,
    roleApi,
    serverLoadApi,
    serverManagerApi,
    updateHistoryApi,
    captchaApi,
    healthCheckApi
];

export const store = configureStore({
    reducer: {
        user: userReducer,
        statistic: statisticReducer,
        captcha: captchaReducer,
        [userApi.reducerPath]: userApi.reducer,
        [statisticApi.reducerPath]: statisticApi.reducer,
        [appApi.reducerPath]: appApi.reducer,
        [bugzillaApi.reducerPath]: bugzillaApi.reducer,
        [regionApi.reducerPath]: regionApi.reducer,
        [complexApi.reducerPath]: complexApi.reducer,
        [complexTypeApi.reducerPath]: complexTypeApi.reducer,
        [errorApi.reducerPath]: errorApi.reducer,
        [issueApi.reducerPath]: issueApi.reducer,
        [productMetricApi.reducerPath]: productMetricApi.reducer,
        [roleApi.reducerPath]: roleApi.reducer,
        [serverLoadApi.reducerPath]: serverLoadApi.reducer,
        [serverManagerApi.reducerPath]: serverManagerApi.reducer,
        [updateHistoryApi.reducerPath]: updateHistoryApi.reducer,
        [captchaApi.reducerPath]: captchaApi.reducer,
        [healthCheckApi.reducerPath]: healthCheckApi.reducer
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(
            allApi.map(api=>api.middleware)
        )
})

setupListeners(store.dispatch);

export const dispatch = store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export const logout = () => {
    dispatch(userSlice.actions.logout());
    allApi.forEach(api=>dispatch(api.util.resetApiState()));
};

export const setUser = (user: IUser) => {
    dispatch(userSlice.actions.setCurrentUser(user));
};