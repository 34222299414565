import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useAppSelector } from "./redux";
import { onlineInterval } from "../constants/others";
import { useSetCurrentOnlineDateTimeMutation } from "../services/UserService";
import useDidMountEffect from "./skipFirstUseEffectRun";
import { isDateValid } from '../utils/utils';

export function useIsOnline() {
    const currentUser = useAppSelector(state => state.user.currentUserState);

    const [setCurrentOnlineDateTime, { isUninitialized }] = useSetCurrentOnlineDateTimeMutation();

    const [isOnline, setIsOnline] = useState(true);

    const currentIsOnline = useRef(true);

    useEffect(() => {
        currentIsOnline.current = isOnline
    }, [isOnline])

    const currentLastOnline = useRef(currentUser?.lastOnline);

    useEffect(() => {
        currentLastOnline.current = currentUser?.lastOnline;
    }, [currentUser])

    const setOnlineStatus = () => {
        if (!currentIsOnline.current) {
            setIsOnline(true);

            if(currentLastOnline.current && isDateValid(currentLastOnline.current)) {
                const diff = Date.now() - new Date(currentLastOnline.current).getTime();
                
                if(diff > onlineInterval) {
                    setCurrentOnlineDateTime();
                }
            }
        }
    }

    const setOfflineStatus = () => {
        if (currentIsOnline.current) {
            setIsOnline(false);
        }
    }

    useDidMountEffect(() => {
        if (isUninitialized) {
            let setIsOnlineInterval: NodeJS.Timer | undefined;

            if (currentUser) {
                setCurrentOnlineDateTime();
                setIsOnlineInterval = setInterval(() => {
                    if (currentIsOnline.current) {
                        setCurrentOnlineDateTime();
                    }
                }, onlineInterval);

                window.addEventListener('focus', setOnlineStatus);
                window.addEventListener('blur', setOfflineStatus);
            }

            return () => {
                clearInterval(setIsOnlineInterval);
                window.removeEventListener('focus', setOnlineStatus);
                window.removeEventListener('blur', setOfflineStatus);
            };
        }
    }, [currentUser])
}