import React, {LazyExoticComponent, MemoExoticComponent} from 'react';
import LoginPage from '../pages/authorization-pages/LoginPage';
import HomePage from '../pages/home-page/HomePage';
import RegionsPage from '../pages/regions-page/RegionsPage';
import ComplexesTypesPage from '../pages/complexes-types-page/ComplexesTypesPage';
import ComplexesPage from '../pages/complexes-page/ComplexesPage';
import MonitoringPage from '../pages/monitoring-page/MonitoringPage';
import ClientsStatisticPage from '../pages/clients-statistic-page/ClientsStatisticPage';
import ProductMetricsPage from '../pages/product-metrics-page/ProductMetricsPage';
import ServerLoadPage from '../pages/server-load-page/ServerLoadPage';
import ErrorGroupsPage from '../pages/error-groups-page/ErrorGroupsPage';
import ServerManagerPage from '../pages/server-manager-page/ServerManagerPage';
import UsersPage from '../pages/users-page/UsersPage';
import RolesPage from '../pages/roles-page/RolesPage';
import AccountPage from '../pages/account-page/AccountPage';
import SettingsPage from '../pages/settings-page/SettingsPage';
import NotFoundedPage from '../pages/not-founded-page/NotFoundedPage';
import RegistrationPage from '../pages/authorization-pages/RegistrationPage';
import ProductMetricsTypesPage from '../pages/product-metrics-types-page/ProductMetricsTypesPage';
import ErrorTriggersPage from '../pages/error-triggers-page/ErrorTriggersPage';
import UpdateHistoryPage from '../pages/update-history-page/UpdateHistoryPage';
import HealthCheckPage from "../pages/health-check-page/HealthCheckPage";
import MonitoringHealthCheckPage from "../pages/monitoring-health-check-page/MonitoringHealthCheckPage";

// const HomePage = React.lazy(() => import('../pages/home-page/HomePage'));
// const NotFoundedPage = React.lazy(() => import('../pages/not-founded-page/NotFoundedPage'));
// const LoginPage = React.lazy(() => import('../pages/authorization-pages/LoginPage'));
// const RegistrationPage = React.lazy(() => import('../pages/authorization-pages/RegistrationPage'));
// const MonitoringPage = React.lazy(() => import('../pages/monitoring-page/MonitoringPage'));
// const ClientsStatisticPage = React.lazy(() => import('../pages/clients-statistic-page/ClientsStatisticPage'));
// const ServerLoadPage = React.lazy(() => import('../pages/server-load-page/ServerLoadPage'));
// const RegionsPage = React.lazy(() => import('../pages/regions-page/RegionsPage'));
// const ComplexesPage = React.lazy(() => import('../pages/complexes-page/ComplexesPage'));
// const ComplexesTypesPage = React.lazy(() => import('../pages/complexes-types-page/ComplexesTypesPage'));
// const RolesPage = React.lazy(() => import('../pages/roles-page/RolesPage'));
// const UsersPage = React.lazy(() => import('../pages/users-page/UsersPage'));
// const SettingsPage = React.lazy(() => import('../pages/settings-page/SettingsPage'));
// const AccountPage = React.lazy(() => import('../pages/account-page/AccountPage'));
// const ProductMetricsPage = React.lazy(() => import('../pages/product-metrics-page/ProductMetricsPage'));
// const ServerManagerPage = React.lazy(() => import('../pages/server-manager-page/ServerManagerPage'));
// const ErrorGroupsPage = React.lazy(() => import('../pages/issues-pages/ErrorGroupsPage'));
// const ReviewIssuesPage = React.lazy(() => import('../pages/issues-pages/ReviewIssuesPage'));
// const IgnoredIssuesPage = React.lazy(() => import('../pages/issues-pages/IgnoredIssuesPage'));

interface AppRoute {
    path: string,
    isPrivateRoute: boolean,
    onlyAdmin: boolean,
    Component: MemoExoticComponent<() => JSX.Element> | (()=>JSX.Element)
}

export const routes: AppRoute[] = [
    {
        path: "/Login",
        isPrivateRoute: false,
        onlyAdmin: false,
        Component: LoginPage
    },
    {
        path: "/Registration",
        isPrivateRoute: false,
        onlyAdmin: false,
        Component: RegistrationPage
    },
    {
        path: "/",
        isPrivateRoute: true,
        onlyAdmin: false,
        Component: HomePage
    },
    {
        path: "/Regions",
        isPrivateRoute: true,
        onlyAdmin: true,
        Component: RegionsPage
    },
    {
        path: "/ComplexType/:id?",
        isPrivateRoute: true,
        onlyAdmin: true,
        Component: ComplexesTypesPage
    },
    {
        path: "/ComplexList",
        isPrivateRoute: true,
        onlyAdmin: true,
        Component: ComplexesPage
    },
    {
        path: "/HealthCheck",
        isPrivateRoute: true,
        onlyAdmin: true,
        Component: HealthCheckPage
    },
    {
        path: "/MonitoringHealthCheck/:id?",
        isPrivateRoute: true,
        onlyAdmin: true,
        Component: MonitoringHealthCheckPage
    },
    {
        path: "/ProductMetricTypes",
        isPrivateRoute: true,
        onlyAdmin: true,
        Component: ProductMetricsTypesPage
    },
    {
        path: "/UpdateHistory",
        isPrivateRoute: true,
        onlyAdmin: true,
        Component: UpdateHistoryPage
    },
    {
        path: "/Monitoring/:id?",
        isPrivateRoute: true,
        onlyAdmin: false,
        Component: MonitoringPage
    },
    {
        path: "/ClientsStatistic",
        isPrivateRoute: true,
        onlyAdmin: true,
        Component: ClientsStatisticPage
    },
    {
        path: "/ProductMetrics/:id?",
        isPrivateRoute: true,
        onlyAdmin: true,
        Component: ProductMetricsPage
    },
    {
        path: "/ServerLoad/:id?",
        isPrivateRoute: true,
        onlyAdmin: true,
        Component: ServerLoadPage
    },
    {
        path: "/ErrorGroups/:id?",
        isPrivateRoute: true,
        onlyAdmin: true,
        Component: ErrorGroupsPage
    },
    {
        path: "/ErrorTriggers/:id?",
        isPrivateRoute: true,
        onlyAdmin: true,
        Component: ErrorTriggersPage
    },
    {
        path: "/ServerManager",
        isPrivateRoute: true,
        onlyAdmin: true,
        Component: ServerManagerPage
    },
    {
        path: "/Users",
        isPrivateRoute: true,
        onlyAdmin: true,
        Component: UsersPage
    },
    {
        path: "/Roles",
        isPrivateRoute: true,
        onlyAdmin: true,
        Component: RolesPage
    },
    {
        path: "/Account",
        isPrivateRoute: true,
        onlyAdmin: false,
        Component: AccountPage
    },
    {
        path: "/Settings",
        isPrivateRoute: true,
        onlyAdmin: false,
        Component: SettingsPage
    },
    {
        path: "*",
        isPrivateRoute: true,
        onlyAdmin: false,
        Component: NotFoundedPage
    }
];