import { memo } from 'react';
import { CaptchaElem } from '../FormElemTypes';
import { useFormContext } from "react-hook-form";
import { IconContext } from 'react-icons';

const Captcha = memo(({ img_base64, isHidden, icon, name, onClick }: CaptchaElem) => {
    const methods = useFormContext();

    return (
        <div
            id="captcha-container"
            className={isHidden ? "captcha-container hidden" : "captcha-container"}
        >
            <button 
                className="captcha-reload-btn"
                type="button" 
                onClick={onClick}
            >
                <IconContext.Provider value={{ className: "captcha-reload-btn-icon" }}>
                    { icon }
                </IconContext.Provider>
            </button>
            
            <img 
                src={ `data:image/png;base64,${img_base64}` } 
                alt="Captcha"
                className="captcha-img" 
            />

            <input
                id="captcha-input"
                type="text"
                {...methods.register(name)}
                className="captcha-input"
                name={name}
                style={{ borderRadius: "5px" }}
            />
        </div>
    );
});

export default Captcha;
