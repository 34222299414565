import React, {memo, useRef} from "react";
import MonitoringHealthCheckPanel from "./HealthCheckMonitoringPanel/MonitoringHealthCheckPanel";
import MonitoringHealthCheckErrorFullInfo from "./ErrorFullInfo/MonitoringHealthCheckErrorFullInfo";

function MonitoringHealthCheckPage() {
    const monitoringHealthCheckPageRef = useRef<HTMLDivElement>(null);

    return (
        <div
            className="monitoring-page row mx-0"
            ref={monitoringHealthCheckPageRef}
        >
            <div className="col-md-4 col-xl-3 col-12 p-3 h-100">
                <MonitoringHealthCheckPanel monitoringPageRef={monitoringHealthCheckPageRef} />
            </div>

            <div className="col-md-8 col-xl-9 col-12 px-0 h-100">
                <MonitoringHealthCheckErrorFullInfo monitoringPageRef={monitoringHealthCheckPageRef} />
            </div>
        </div>
    )
}

export default memo(MonitoringHealthCheckPage);