import React, { memo } from 'react'
import { useParams } from 'react-router-dom';
import { ErrorTypesIcons } from '../../../models/entities/EErrorTypes';
import { MdContentCopy, MdMoreVert } from 'react-icons/md';
import { TiArrowBack } from 'react-icons/ti';
import { RSwal } from '../../../constants/sweetAlert';
import LoadingBlock from '../../../components/LoadingBlock';
import ErrorMsg from '../../monitoring-page/ErrorFullInfo/ErrorMsg';
import {useGetHealthCheckErrorQuery} from "../../../services/HealthCheckService";

const MonitoringHealthCheckErrorFullInfo = memo(({ monitoringPageRef }: { monitoringPageRef: React.RefObject<HTMLDivElement> }) => {
    const selectedErrorId = useParams<{ id?: string }>().id;

    const { data: errorFullInfo, isFetching: errorFullInfoIsLoading } = useGetHealthCheckErrorQuery(Number(selectedErrorId), {
        skip: typeof selectedErrorId !== 'string'
    });

    const errorData = errorFullInfo ? { ...errorFullInfo.data } : undefined;

    return (
        <div className='error-full-info'>
            <div
                className="error-full-info-back-btn btn btn-primary justify-content-center mb-3"
                onClick={() => monitoringPageRef.current ? monitoringPageRef.current.scrollLeft = 0 : null}
            >
                <TiArrowBack className='me-1' />
                К списку ошибок
            </div>

            {selectedErrorId && (!errorFullInfo || !errorFullInfo.success===false) ?
                <>
                    <div className="error-full-info-header">
                        {errorData && errorFullInfo?.success && !errorFullInfoIsLoading ?
                            <>
                                <div className="error-full-info-header-icon">
                                    {ErrorTypesIcons[4]}
                                </div>
                                <div className="row mx-0 flex-grow-1 overflow-hidden">
                                    <h3
                                        className="col-12 px-0 text-truncate"
                                        title={errorData.exception_name}
                                    >
                                        {errorData.exception_name}
                                    </h3>
                                    <h6
                                        className={"col-12 px-0 text-truncate text-info " + (errorData.description ? 'my-1' : '')}
                                        title={errorData.description}
                                    >
                                        {errorData.description}
                                    </h6>
                                </div>

                                <div className="error-full-info-header-more">
                                    <MdMoreVert
                                        className="dropdown"
                                        type="button"
                                        id="errorFullInfoHeaderMore"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="true"
                                    />
                                    <ul
                                        className="dropdown-menu shadow"
                                        aria-labelledby="errorFullInfoHeaderMore"
                                    >
                                        <li
                                            onClick={() => {
                                                window.navigator.clipboard.writeText(
                                                    `${window.location.origin + process.env.PUBLIC_URL}/MonitoringHealthCheck/${errorData.id}`
                                                );
                                                RSwal.fire({
                                                    toast: true,
                                                    icon: 'success',
                                                    position: 'top',
                                                    title: 'Тикет скопирован!',
                                                    showConfirmButton: false,
                                                    timer: 1500,
                                                    timerProgressBar: true
                                                });
                                            }}
                                        >
                                            Скопировать тикет
                                            <MdContentCopy className='ms-1' />
                                        </li>
                                    </ul>
                                </div>
                            </>
                            : <LoadingBlock
                                height={80}
                                rounded
                            />}
                    </div>

                    <div className="error-full-info-details">
                        {errorData && errorFullInfo?.success && !errorFullInfoIsLoading ?
                            <>
                                <div className="d-flex flex-nowrap">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th>Время на сервере Мониторинга (МСК):</th>
                                                <td>{errorData.datemsk}</td>
                                            </tr>
                                            <tr>
                                                <th>HealthCheck URL:</th>
                                                <td>{errorData.health_check_url}</td>
                                            </tr>
                                            <tr>
                                                <th>Регион:</th>
                                                <td>{errorData.region}</td>
                                            </tr>
                                            <tr>
                                                <th>Система:</th>
                                                <td>{errorData.complex_type}</td>
                                            </tr>
                                            
                                        </tbody>
                                    </table>
                                </div>
                            </>
                            : <LoadingBlock
                                rounded
                            />}
                    </div>
                    
                    <div className="error-full-info-msg">
                        {errorData && errorFullInfo?.success && !errorFullInfoIsLoading ?
                            <ErrorMsg errorMsg={errorData.description} />
                            : <LoadingBlock rounded />}
                    </div>
                </>
            : (errorFullInfo?.success === false ? 
                <h5>{errorFullInfo.message}</h5> : 
                <h5>Выберите ошибку из списка</h5>)}
        </div>
    )
})

export default MonitoringHealthCheckErrorFullInfo;