import { ElementVariant, FormElemTypes, SelectElem } from "../components/Form/FormElemTypes";
import { ComplexProperties, ComplexPropertiesNames, IComplex } from "../models/entities/IComplex";
import { ComplexTypeProperties, ComplexTypePropertiesNames, IComplexType } from "../models/entities/IComplexType";
import { IRegion, RegionProperties, RegionPropertiesNames } from "../models/entities/IRegion";

export enum FormTypes {
    create = 'CREATE',
    update = 'UPDATE',
    delete = 'DELETE'
}

export enum FormNames {
    region = 'REGION',
    complexType = 'COMPLEX_TYPE',
    complex = 'COMPLEX'
}

type RegionFormElemsProps = {
    formName: FormNames.region,
    formType: FormTypes.create,
    options: {
        [RegionProperties.timezone]: SelectElem['options']
    },
    row?: IRegion
} | {
    formName: FormNames.region,
    formType: FormTypes.update | FormTypes.delete,
    options: {
        [RegionProperties.timezone]: SelectElem['options']
    },
    row: IRegion
}

type ComplexTypeFormElemsProps = {
    formName: FormNames.complexType,
    formType: FormTypes.create,
    options?: undefined,
    row?: IComplexType
} | {
    formName: FormNames.complexType,
    formType: FormTypes.update | FormTypes.delete,
    options?: undefined,
    row: IComplexType
}

type ComplexFormElemsProps = {
    formName: FormNames.complex,
    formType: FormTypes.create,
    options: {
        [ComplexProperties.region]: SelectElem['options'],
        [ComplexProperties.type]: SelectElem['options']
    },
    row?: IComplex
} | {
    formName: FormNames.complex,
    formType: FormTypes.update | FormTypes.delete,
    options: {
        [ComplexProperties.region]: SelectElem['options'],
        [ComplexProperties.type]: SelectElem['options']
    },
    row: IComplex
}

export const getFormElems = (
    { formName, formType, options, row }: RegionFormElemsProps | ComplexTypeFormElemsProps | ComplexFormElemsProps
): ElementVariant[] => {
    let currentElems: ElementVariant[];

    if (formName === FormNames.region) {
        currentElems = [
            {
                type: FormElemTypes.text,
                name: RegionProperties.name,
                label: RegionPropertiesNames.name,
                htmlAttributes: {
                    defaultValue: row?.name,
                    placeholder: "Москва",
                    autoFocus: true,
                    required: true,
                    readOnly: formType === FormTypes.delete
                }
            },
            {
                type: FormElemTypes.select,
                name: RegionProperties.timezone,
                label: RegionPropertiesNames.timezone,
                options: options[RegionProperties.timezone],
                optionsDisabled: formType === FormTypes.delete,
                htmlAttributes: {
                    defaultValue: row?.timezone,
                    disabled: formType === FormTypes.delete
                }
            },
            {
                type: FormElemTypes.url,
                name: RegionProperties.baseUrl,
                label: RegionPropertiesNames.baseUrl,
                htmlAttributes: {
                    defaultValue: row?.baseUrl,
                    placeholder: "https://site.ru",
                    readOnly: formType === FormTypes.delete
                }
            },
            {
                type: FormElemTypes.url,
                name: RegionProperties.managerUrl,
                label: RegionPropertiesNames.managerUrl,
                htmlAttributes: {
                    defaultValue: row?.managerUrl,
                    placeholder: "https://site.ru/servermanager",
                    readOnly: formType === FormTypes.delete
                }
            },
            {
                type: FormElemTypes.radio,
                name: RegionProperties.useAuth,
                label: RegionPropertiesNames.useAuth,
                defaultValue: row?.useAuth ?? false,
                items: [
                    {
                        label: 'Да',
                        value: true,
                        disabled: formType === FormTypes.delete
                    },
                    {
                        label: 'Нет',
                        value: false,
                        disabled: formType === FormTypes.delete
                    }
                ]
            },
            {
                type: FormElemTypes.text,
                name: RegionProperties.userName,
                label: RegionPropertiesNames.userName,
                htmlAttributes: {
                    defaultValue: row?.userName,
                    placeholder: RegionPropertiesNames.userName,
                    readOnly: formType === FormTypes.delete
                }
            }
        ];

        if (formType === FormTypes.create || formType === FormTypes.update) {
            currentElems.push({
                type: FormElemTypes.text,
                name: RegionProperties.userPassword,
                label: RegionPropertiesNames.userPassword,
                htmlAttributes: {
                    placeholder: "******"
                }
            })
        }

        if (formType === FormTypes.update || formType === FormTypes.delete) {
            currentElems.unshift({
                type: FormElemTypes.text,
                name: ComplexProperties.id,
                label: ComplexPropertiesNames.id,
                htmlAttributes: {
                    defaultValue: String(row?.id),
                    placeholder: ComplexPropertiesNames.id,
                    required: true,
                    readOnly: true
                }
            })
        }

        return currentElems;
    }

    if (formName === FormNames.complexType) {
        currentElems = [
            {
                type: FormElemTypes.text,
                name: ComplexTypeProperties.name,
                label: ComplexTypePropertiesNames.name,
                htmlAttributes: {
                    required: true,
                    defaultValue: row?.name,
                    readOnly: formType === FormTypes.delete
                }
            }
        ];

        if (formType === FormTypes.update || formType === FormTypes.delete) {
            currentElems.unshift({
                type: FormElemTypes.text,
                name: ComplexProperties.id,
                label: ComplexPropertiesNames.id,
                htmlAttributes: {
                    defaultValue: String(row?.id),
                    placeholder: ComplexPropertiesNames.id,
                    required: true,
                    readOnly: true
                }
            })
        }

        return currentElems;
    }

    if (formName === FormNames.complex) {
        currentElems = [
            {
                type: FormElemTypes.select,
                name: ComplexProperties.region,
                label: ComplexPropertiesNames.region,
                options: options[ComplexProperties.region],
                optionsDisabled: formType === FormTypes.delete,
                htmlAttributes: {
                    required: true,
                    defaultValue: row?.region,
                    disabled: formType === FormTypes.delete
                }
            },
            {
                type: FormElemTypes.select,
                name: ComplexProperties.type,
                label: ComplexPropertiesNames.type,
                options: options[ComplexProperties.type],
                optionsDisabled: formType === FormTypes.delete,
                htmlAttributes: {
                    required: true,
                    defaultValue: row?.type,
                    disabled: formType === FormTypes.delete
                }
            },
            {
                type: FormElemTypes.text,
                name: ComplexProperties.healthCheckUrl,
                label: ComplexPropertiesNames.healthCheckUrl,
                htmlAttributes: {
                    defaultValue: row?.healthCheckUrl,
                    disabled: formType === FormTypes.delete
                }
            },
            {
                type: FormElemTypes.radio,
                name: ComplexProperties.ignore,
                label: ComplexPropertiesNames.ignore,
                defaultValue: row?.ignore ?? false,
                items: [
                    {
                        label: "Да",
                        value: true,
                        disabled: formType === FormTypes.delete
                    },
                    {
                        label: "Нет",
                        value: false,
                        disabled: formType === FormTypes.delete
                    }
                ]
            },
            {
                type: FormElemTypes.text,
                name: ComplexProperties.replicationToken,
                label: ComplexPropertiesNames.replicationToken,
                htmlAttributes: {
                    defaultValue: row?.replicationToken,
                    disabled: formType === FormTypes.delete
                }
            }
        ];

        if (formType === FormTypes.update || formType === FormTypes.delete) {
            currentElems.unshift({
                type: FormElemTypes.text,
                name: ComplexProperties.id,
                label: ComplexPropertiesNames.id,
                htmlAttributes: {
                    defaultValue: String(row?.id),
                    placeholder: ComplexPropertiesNames.id,
                    required: true,
                    readOnly: true
                }
            })
        }

        return currentElems;
    }

    else return [];
} 