import React, { memo } from 'react'
import { history, nameof } from '../../utils/utils';
import IErrorCountByComplex from '../../models/responses/IErrorCountByComplex';
import RsuiteTable, { ColumnsList } from '../../components/rsuite-table/RsuiteTable';
import RenderData from '../../components/render-condition/RenderData';
import LoadingBlock from '../../components/LoadingBlock';
import { MonitoringPanelLocState } from '../monitoring-page/MonitoringPanel/MonitoringPanel';
import { useLocation } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import { PiWarningCircleFill } from "react-icons/pi";

type ErrorsCountTableProps = {
    data: IErrorCountByComplex[] | undefined
    isLoading: boolean
    isSuccess: boolean
    page: string
}

const columns: ColumnsList = [
    {
        columnProps: {
            sortable: true,
            flexGrow: 1,
            fullText: true
        },
        headerProps: {
            children: 'Регион'
        },
        cellProps: {
            dataKey: nameof<IErrorCountByComplex>('region')
        },
        searchable: true
    },
    {
        columnProps: {
            sortable: true,
            flexGrow: 1,
            fullText: true
        },
        headerProps: {
            children: "Система"
        },
        cellProps: {
            dataKey: nameof<IErrorCountByComplex>('complex_type')
        },
        searchable: true
    },
    {
        columnProps: {
            sortable: true,
            fullText: true
        },
        headerProps: {
            children: "Ошибок"
        },
        cellProps: {
            dataKey: nameof<IErrorCountByComplex>('count')
        },
        searchable: true
    }
];

const healthCheckColumns: ColumnsList = [
    {
        columnProps: {
            sortable: true,
            flexGrow: 1,
            fullText: true
        },
        headerProps: {
            children: 'Регион'
        },
        cellProps: {
            dataKey: nameof<IErrorCountByComplex>('region')
        },
        searchable: true
    },
    {
        columnProps: {
            sortable: true,
            flexGrow: 1,
            fullText: true
        },
        headerProps: {
            children: "Система"
        },
        cellProps: {
            dataKey: nameof<IErrorCountByComplex>('complex_type')
        },
        searchable: true
    },
    {
        columnProps: {
            sortable: true,
            fullText: true,
            minWidth: 80
        },
        headerProps: {
            children: "Ошибок"
        },
        cellProps: {
            dataKey: nameof<IErrorCountByComplex>('count')
        },
        searchable: true
    }
    ,
    {
        columnProps: {
            sortable: true,
            fullText: true,
            minWidth: 80
        },
        headerProps: {
            children: "Статус"
        },
        cellProps: {
            dataKey: nameof<IErrorCountByComplex>('status'),
            renderCell: (contentChildren) => {
                const status = contentChildren;
                
                let statusIcon;
                
                if (status === true) {
                    statusIcon = <FaCheckCircle style={{ color: 'green', width: '20px', height: '100%' }} />
                } else if (status === false) {
                    statusIcon = <PiWarningCircleFill style={{ color: 'red', width: '22px', height: '100%' }} />
                }
                
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                        {statusIcon}
                    </div>
                );
            }
        },
        searchable: true
    },
    {
        columnProps: {
            sortable: true,
            fullText: true,
            minWidth: 270
        },
        headerProps: {
            children: "Дата последней проверки"
        },
        cellProps: {
            dataKey: nameof<IErrorCountByComplex>('lastExecution')
        },
        searchable: true
    }
];

const ErrorsCountTable = memo(({ data, isLoading, isSuccess, page }: ErrorsCountTableProps) => {
    const location = useLocation();

    const redirectRouter = location.pathname === "/HealthCheck" ? "/MonitoringHealthCheck" : "/Monitoring";
    
    return (
        <div className='home-errors-count-table'>
            {!!data && !isLoading ?
                <RenderData
                    component={<RsuiteTable
                        virtualized={true}
                        data={data}
                        columns={page === "Main" ? columns : healthCheckColumns}
                        withTotalRows
                        onRowClick={(row: IErrorCountByComplex) => {
                            const locState: MonitoringPanelLocState = {
                                regions: [row.region_id],
                                complexTypes: [row.complex_type_id],
                                startDateTypeDate: new Date(new Date().valueOf() - 1000 * 60 * 60 * 24)
                            }
                            if (history.navigate) {
                                history.navigate(
                                    redirectRouter,
                                    {
                                        state: locState
                                    }
                                )
                            }
                        }}
                        locale={{
                            emptyMessage: "Данных за 24 часа не найдено"
                        }}
                    />}
                    isLoading={isLoading}
                    isSuccess={isSuccess}
                /> : <LoadingBlock rounded />}
        </div>
    )
})

export default ErrorsCountTable