import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getCookie } from "../../utils/utils";

interface CaptchaState {
    captchaImg: string | undefined;
    isCaptcha: boolean;
}

const initialState: CaptchaState = {
    captchaImg: getCookie().captchaImg !== undefined ? getCookie().captchaImg.replaceAll('"', "") : null,
    isCaptcha: getCookie().isCaptcha !== undefined ? JSON.parse(getCookie().isCaptcha) : true
};

export const captchaSlice = createSlice({
    name: 'captcha',
    initialState,
    reducers: {
        setCaptchaImg(state, action: PayloadAction<string | undefined>) {
            state.captchaImg = action.payload;
            if (action.payload !== undefined) {
                document.cookie=`captchaImg=${JSON.stringify(state.captchaImg)};max-age=300`;
            }
        },
        setIsCaptcha(state, action: PayloadAction<boolean>) {
            state.isCaptcha = action.payload;
            document.cookie=`isCaptcha=${JSON.stringify(state.isCaptcha)};max-age=300`;
        },
        removeCaptchaData(state) {
            document.cookie = `captchaImg=;expires=${new Date(0)}`;
            document.cookie = `isCaptcha=;expires=${new Date(0)}`;
        },
    }
});

export const { setIsCaptcha, setCaptchaImg, removeCaptchaData } = captchaSlice.actions;
export const captchaReducer = captchaSlice.reducer;
