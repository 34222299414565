import { ReactNode, memo, useEffect, useLayoutEffect, useRef, useState, RefObject, useCallback } from 'react'
import { ResponsiveContainer, LineChart, BarChart, Bar, XAxis, YAxis, CartesianGrid, Line, Cell, Legend, Tooltip, Surface, Symbols, DotProps, CartesianAxis } from 'recharts'
import { TGraphsData } from '../../../models/responses/TGraphsData';
import { selectColor, selectDarkColor } from '../../../store/globalVariables';
import { CustomLegend, CustomLegendProps } from './CustomLegend';
import { CustomTooltip } from './CustomTooltip';
import { ItemType } from '../../../utils/utilsTypes';
import CustomXAxisTick, { CustomXAxisTickProps } from './CustomXAxisTick';
import LoadingBlock from '../../../components/LoadingBlock';
import { getDateOfStartLastDay, history } from '../../../utils/utils';
import { MonitoringPanelLocState } from '../../monitoring-page/MonitoringPanel/MonitoringPanel';
import IErrorCountByComplexType from '../../../models/responses/IErrorCountByComplexType';
import NoData from '../../../components/NoData';
import { MdFullscreen, MdPlace, MdSettingsSystemDaydream } from 'react-icons/md';
import { renderToString } from 'react-dom/server';
import { useLazyGetRegionsQuery } from '../../../services/RegionService';
import { createPortal } from 'react-dom';
import { GraphContextMenu } from '../../../components/GraphContextMenu';
import { useShowContextMenu } from '../../../hooks/showContextMenu';
import { useOnKeyDown } from '../../../hooks/onKeyDown';
import { useLocation } from "react-router-dom";

let keysColors: { [key: string]: string } = {};

type GraphsContainerProps = {
    graphsData: TGraphsData | undefined,
    graphsDataIsLoading: boolean,
    errorsByComplexType: IErrorCountByComplexType[] | undefined,
    graphsTypeIsRegions: boolean,
    setGraphsTypeIsRegions: (isTrue: boolean) => void
}

interface CustomDotProps<T> extends DotProps {
    payload: T,
    dataKey: string,
    index: number,
    value: number,
    fill: string,
    stroke: string,
    strokeWidth: number
}

type showTooltipProps = {
    text: string,
    x: number,
    y: number,
    bgColor: string,
    position: "fixed" | "absolute",
    opacity?: string
    transform?: string,
    textAlign?: string,
    borderRadius?: string
}

const fullScreenClass = "full-screen";

const GraphsContainer = memo(({ graphsData, graphsDataIsLoading, errorsByComplexType, graphsTypeIsRegions, setGraphsTypeIsRegions }: GraphsContainerProps) => {
    const [keys, setKeys] = useState<string[]>([]);

    const legendItems = useRef<CustomLegendProps['data']>([]);

    const [deletedKeys, setDeletedKeys] = useState<string[]>([]);
    const [lines, setLines] = useState<ReactNode[]>([]);
    const [lineGraphData, setLineGraphData] = useState<dataArrayProps[] | undefined>();
    const [barGraphData, setBarGraphData] = useState<TGraphsData['barData'] | undefined>();

    const graphItemsIds = useRef<{ [key: string]: number }>({});

    const location = useLocation();

    const redirectRouter = location.pathname === "/HealthCheck" ? "/MonitoringHealthCheck" : "/Monitoring";

    useEffect(() => {
        if (graphsData && !graphsDataIsLoading) {
            legendItems.current = [];
            const currentLineGraphData = convertLineGraphData(graphsData.lineData);
            graphsData.keys.forEach((key, index) => {
                keysColors[key] = selectColor(index);
                legendItems.current.push({ name: key })
            })
            setKeys(graphsData.keys);
            setLineGraphData(currentLineGraphData);
            setBarGraphData(graphsData.barData);
        }
    }, [graphsData, graphsDataIsLoading]);

    const [getRegions, {data: regions}] = useLazyGetRegionsQuery();

    useLayoutEffect(() => {
        if (Array.isArray(errorsByComplexType) && errorsByComplexType.length > 0) {
            const currentGraphItemsIds: { [key: string]: number } = {};

            if(graphsTypeIsRegions) {
                const regionsResponse = getRegions(undefined, true).unwrap();

                regionsResponse.then(regions=>{
                    if(regions.success && regions.data) {
                        regions.data.forEach(region => {
                            currentGraphItemsIds[region.name] = region.id
                        });
                    }
                })
            }
            else {
                errorsByComplexType.forEach(item => {
                    currentGraphItemsIds[item.complex_type] = item.complex_type_id
                });
            }

            graphItemsIds.current = currentGraphItemsIds;
        }
    }, [graphsTypeIsRegions, errorsByComplexType, regions]);

    useEffect(() => {
        if (lineGraphData && graphsData) {
            const currentLines: ReactNode[] = [];

            graphsData.keys.forEach((key, index) => {
                currentLines.push(
                    <Line
                        key={`line_${index}`}
                        dataKey={key + (deletedKeys.includes(key) ? " " : "")}
                        isAnimationActive={false}
                        stroke={keysColors[key]}
                        strokeWidth={2}
                        dot={{
                            strokeWidth: 1,
                            cursor: 'pointer',
                            fill: keysColors[key],
                            onClick: (dotProps: CustomDotProps<ItemType<TGraphsData['lineData']>>) => {
                                if (graphsData?.lineData) {
                                    const dotDateTime = new Date(graphsData.lineData[dotProps.index].hour);
                                    const dotDateTimeNextHour = new Date(new Date(dotDateTime).setHours(dotDateTime.getHours() + 1));
                                    const selectedLineDotId: number | undefined = graphItemsIds.current[dotProps.dataKey];

                                    if (selectedLineDotId) {
                                        const locState: MonitoringPanelLocState = {
                                            startDateTypeDate: dotDateTime,
                                            endDateTypeDate: dotDateTimeNextHour
                                        }

                                        if(graphsTypeIsRegions) {
                                            locState.regions = [selectedLineDotId]
                                        }
                                        else {
                                            locState.complexTypes = [selectedLineDotId]
                                        }

                                        if (history.navigate) {
                                            history.navigate(
                                                redirectRouter,
                                                {
                                                    state: locState
                                                }
                                            )
                                        }
                                    }
                                }
                            },
                            onMouseEnter: (dotProps: CustomDotProps<ItemType<TGraphsData['lineData']>>, event) => 
                                handleDotMouseEnter(dotProps, event.target as HTMLElement, event.pageX, event.pageY)
                            ,
                            onMouseLeave: (dotProps, elem) => handleDotMouseLeave(elem.target as HTMLElement)
                        }}
                    />
                );
            });

            setLines(currentLines);
        }
    }, [lineGraphData, deletedKeys])

    useEffect(() => {
        if (barGraphData && graphsData?.barData) {
            setBarGraphData(graphsData.barData.filter(item => !deletedKeys.includes(item.name)))
        }
    }, [deletedKeys, graphsData])

    function handleLegendClick(dataKey: string) {
        if (deletedKeys.includes(dataKey)) {
            setDeletedKeys([...deletedKeys].filter(key => key !== dataKey));
        }
        else {
            setDeletedKeys(current => [...current, dataKey])
        }
    }

    function handleLegendRightClick(dataKey: string) {
        if (JSON.stringify(deletedKeys) === JSON.stringify([...keys].filter(key => key !== dataKey))) {
            setDeletedKeys([]);
        }
        else if (keys.length > 0) {
            setDeletedKeys([...keys].filter(key => key !== dataKey))
        }
    }

    function handleDotMouseEnter(dotProps: CustomDotProps<ItemType<TGraphsData['lineData']>>, target: HTMLElement, x: number, y: number) {
        (target as HTMLDivElement).style.fill = 'stroke' in dotProps ? selectDarkColor(dotProps.stroke as string) : '';
        (target as HTMLDivElement).style.strokeWidth = '3';

        if (
            graphsData
        ) {
            const currentDate: string = graphsData.lineData[dotProps.index].hour.split('T')[0];
            const text = renderToString(<>
                <div>{currentDate}</div>
                <div className='border-bottom'>
                    {dotProps.payload.hour}
                </div>
                <div>{dotProps.dataKey}: {dotProps.payload[dotProps.dataKey]}</div>
            </>);
            // const text = currentDate +
            //     `\n<div class="border-bottom">${dotProps.payload.hour}</div>${dotProps.dataKey}: ${dotProps.payload[dotProps.dataKey]}`;

            let transform: string | undefined = undefined;

            // Отображать подсказку слева от курсора, если точка находится на правой стороне графика
            if (dotProps.index > (graphsData.lineData.length / 2)) {
                transform = 'translate(calc(-100% - 5px), -100%)';
            }
            else {
                transform = 'translate(7px, calc(-100% - 7px))';
            }

            showTooltip({
                text: text,
                bgColor: dotProps.stroke,
                x: x,
                y: y,
                transform: transform,
                position: "fixed"
            });
        }
    }

    function handleDotMouseLeave(target: HTMLElement) {
        (target as HTMLDivElement).style.strokeWidth = '1';
        hideTooltip();
    }

    function handleXAxisTickMouseEnter(index: number, x: number, y: number) {
        if (graphsData) {
            let text: string = "";
            let currentItem = { ...graphsData.lineData[index] };
            const currentDateTime: string[] = currentItem.hour.split('T');
            const currentDate = currentDateTime[0];
            const currentTimeArray = currentDateTime[1].split(':');

            text += `${currentDate}\n${currentTimeArray[0]}:${currentTimeArray[1]}\n`;
            Object.keys(currentItem).forEach(key => {
                if (key !== 'hour') {
                    text += renderToString(
                        <div className='text-nowrap'>
                            <div 
                                className="legend-item-marker d-inline-block" 
                                style={{backgroundColor: keysColors[key]}}
                            ></div>
                            {key}: {currentItem[key]}
                            <br />
                        </div>)
                }
            })
            showTooltip({
                text: text,
                bgColor: 'var(--dark)',
                x: x,
                y: y,
                opacity: '0.85',
                transform: index > (graphsData.lineData.length / 2) ? 'translate(-110%, -50%)' : 'translate(10%, -50%)',
                textAlign: 'left',
                borderRadius: '0.25rem',
                position: "absolute"
            });
        }
    }

    const handleXAxisTickClick = (tickIdx: number) => {
        if (graphsData?.lineData && Array.isArray(errorsByComplexType) && errorsByComplexType.length > 0) {
            const dotDateTime = new Date(graphsData.lineData[tickIdx].hour);
            const dotDateTimeNextHour = new Date(new Date(dotDateTime).setHours(dotDateTime.getHours() + 1));

            const locState: MonitoringPanelLocState = {
                startDateTypeDate: dotDateTime,
                endDateTypeDate: dotDateTimeNextHour
            }
            if (history.navigate) {
                history.navigate(
                    redirectRouter,
                    {
                        state: locState
                    }
                )
            }
        }
    }

    function hideTooltip() {
        if (customTooltipRef.current) {
            customTooltipRef.current.style.opacity = '0';
        }
    }

    function showTooltip({ text, x, y, bgColor, opacity, transform, textAlign, borderRadius, position }: showTooltipProps) {
        if (
            text &&
            customTooltipRef.current &&
            customTooltipContentRef.current &&
            customTooltipBgRef.current &&
            graphsData
        ) {
            customTooltipContentRef.current.innerHTML = text;
            
            customTooltipRef.current.style.position = position;
            customTooltipRef.current.style.opacity = '1';
            customTooltipRef.current.style.left = `${x}px`;
            customTooltipRef.current.style.top = `${y}px`;
            customTooltipRef.current.style.transform = transform ?? "";
            customTooltipRef.current.style.textAlign = textAlign ?? "";

            customTooltipBgRef.current.style.opacity = opacity ?? '1';
            customTooltipBgRef.current.style.backgroundColor = bgColor;
            customTooltipBgRef.current.style.borderRadius = borderRadius ?? '';
        }
    }

    const customTooltipRef = useRef<HTMLDivElement>(null);
    const customTooltipContentRef = useRef<HTMLDivElement>(null);
    const customTooltipBgRef = useRef<HTMLDivElement>(null);

    const toggleFullScreenGraph = (graph: HTMLElement | null | undefined) => {
        if (graph?.classList) {
            graph.classList.toggle(fullScreenClass);
        }

        focusOnGraph(graph);

        closeContextMenu();
    }

    const focusOnGraph = (graph: HTMLElement | null | undefined) => { 
        const chartContent = graph?.children[0] as HTMLElement | undefined;

        if(chartContent) {
            chartContent.tabIndex = 1;
            chartContent.focus();
        }
    }

    const removeFullScreenGraph = useCallback((graph: HTMLElement | null | undefined) => {
        if (graph?.classList) {
            graph.classList.remove(fullScreenClass);
        }

        closeContextMenu();
    },[])

    const closeContextMenu = () => {
        if (lineChartContextMenuRef?.current) {
            lineChartContextMenuRef.current.classList.remove("show");
        }
        if (barChartContextMenuRef?.current) {
            barChartContextMenuRef.current.classList.remove("show");
        }
    }

    const customLegendRef = useRef<HTMLDivElement>(null);

    // Добавление возможности раскрытия графика LineChart во весь экран
    const lineChartRef = useRef<RefObject<HTMLElement>>(null);
    const lineChartContextMenuRef = useRef<HTMLDivElement>(null);

    useShowContextMenu(lineChartRef?.current, lineChartContextMenuRef, customLegendRef);
    useOnKeyDown({
        code: "Escape",
        target: lineChartRef?.current,
        onKeyDown: removeFullScreenGraph
    });
    useOnKeyDown({
        code: "KeyF",
        target: lineChartRef?.current,
        onKeyDown: toggleFullScreenGraph
    });

    // Добавление возможности раскрытия графика BarChart во весь экран
    const barChartRef = useRef<RefObject<HTMLElement>>(null);
    const barChartContextMenuRef = useRef<HTMLDivElement>(null);

    useShowContextMenu(barChartRef?.current, barChartContextMenuRef, customLegendRef);
    useOnKeyDown({
        code: "Escape",
        target: barChartRef?.current,
        onKeyDown: removeFullScreenGraph
    });
    useOnKeyDown({
        code: "KeyF",
        target: barChartRef?.current,
        onKeyDown: toggleFullScreenGraph
    });

    return (
        <div className="home-graphs-container user-select-none position-relative">
            {
                !graphsDataIsLoading &&
                lineGraphData?.length! <= 0 &&
                barGraphData?.length! <= 0 &&
                <NoData message="Данных за 24 часа не найдено" />
            }

            {!graphsDataIsLoading ?
                <>
                    {/* Line Chart */}
                    <ResponsiveContainer
                        className='line-chart'
                        width='100%'
                        height='50%'
                        ref={lineChartRef}
                    >
                        {lineGraphData ?
                            <LineChart
                                data={lineGraphData}
                                margin={{
                                    right: 15
                                }}
                                tabIndex={1}
                            >
                                <Legend
                                    verticalAlign="top"
                                    wrapperStyle={{ top: 0, right: 0, width: '100%' }}
                                    content={() =>
                                        <CustomLegend
                                            innerRef={customLegendRef}
                                            data={legendItems.current}
                                            deletedKeys={deletedKeys}
                                            handleLegendClick={(dataKey: string) => handleLegendClick(dataKey.trim())}
                                            handleLegendRightClick={(dataKey: string) => handleLegendRightClick(dataKey.trim())}
                                            handleLegendMouseEnter={(index: number) => {
                                                const legendItem = document.querySelectorAll('.recharts-line>path')[index] as HTMLElement;
                                                if (legendItem) legendItem.style.strokeWidth = '4';
                                            }}
                                            handleLegendMouseLeave={(index: number) => {
                                                const legendItem = document.querySelectorAll('.recharts-line>path')[index] as HTMLElement;
                                                if (legendItem) legendItem.style.strokeWidth = '2';
                                            }}
                                        />}
                                />
                                <CartesianGrid stroke="#ccc" strokeWidth={1} />
                                <XAxis
                                    dataKey="hour"
                                    angle={-45}
                                    dy={10}
                                    dx={-10}
                                    minTickGap={-10}
                                    height={50}
                                    fontSize={12}
                                    tick={(tickProps: CustomXAxisTickProps['tickProps']) => <CustomXAxisTick
                                        tickProps={tickProps}
                                        handleMouseEnter={(index: number) => {
                                            handleXAxisTickMouseEnter(index, tickProps.x, tickProps.y)
                                        }}
                                        handleMouseLeave={() => hideTooltip()}
                                        handleClick={(tickIdx: number) => handleXAxisTickClick(tickIdx)}
                                    />}
                                />
                                <YAxis tickCount={9}/>
                                {lines}
                            </LineChart>
                            : <LoadingBlock />}
                    </ResponsiveContainer>

                    {createPortal(
                        <GraphContextMenu
                            innerRef={lineChartContextMenuRef}
                        >
                            <div 
                                className='graph-context-menu-item'
                                onClick={()=>toggleFullScreenGraph(lineChartRef?.current?.current)}
                            >
                                <MdFullscreen />
                                Во весь экран
                            </div>
                        </GraphContextMenu>,
                        window.document.body
                    )}

                    {/* Line Graph Tooltip */}
                    <CustomTooltip
                        className='line-custom-tooltip'
                        refs={{
                            containerRef: customTooltipRef,
                            contentRef: customTooltipContentRef,
                            bgRef: customTooltipBgRef
                        }}
                    />

                    {/* Bar Chart */}
                    <ResponsiveContainer
                        className='bar-chart'
                        width='100%'
                        height='50%'
                        ref={barChartRef}
                    >
                        {barGraphData ?
                            <BarChart
                                tabIndex={1}
                                data={barGraphData}
                                onClick={(barProps) => {
                                    if (barProps && barProps.activeLabel) {
                                        const selectedBarId: number | undefined = graphItemsIds.current[barProps.activeLabel];
                                        
                                        if (selectedBarId) {
                                            const locState: MonitoringPanelLocState = {
                                                startDateTypeDate: getDateOfStartLastDay()
                                            }

                                            if(graphsTypeIsRegions) {
                                                locState.regions = [selectedBarId]
                                            }
                                            else {
                                                locState.complexTypes = [selectedBarId]
                                            }
                                            
                                            if (history.navigate) {
                                                history.navigate(
                                                    redirectRouter,
                                                    {
                                                        state: locState
                                                    }
                                                )
                                            }
                                        }
                                    }
                                }}
                                margin={{
                                    right: 15
                                }}
                            >
                                <CartesianGrid stroke="#ccc" strokeWidth={1} />
                                <YAxis tickCount={8} />
                                <XAxis
                                    dataKey="name"
                                    fontSize={12}
                                    minTickGap={3}
                                />
                                <Tooltip
                                    labelFormatter={() => ''}
                                    cursor={true}
                                    content={(props) => {
                                        const activeItem = barGraphData.find(elem => elem.name === props.label);

                                        const tooltipText = activeItem ?
                                            `${activeItem.name}: ${activeItem.count}`
                                            : '';

                                        return <CustomTooltip
                                            label={props.label}
                                            children={tooltipText}
                                            keysColors={keysColors}
                                        />
                                    }}
                                />
                                <Bar dataKey='count'>
                                    {barGraphData.map((item, index) =>
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={keysColors[item.name]}
                                        />
                                    )}
                                </Bar>
                            </BarChart>
                            : <LoadingBlock />}
                    </ResponsiveContainer>

                    {createPortal(
                        <GraphContextMenu
                            innerRef={barChartContextMenuRef}
                        >
                            <div 
                                className='graph-context-menu-item'
                                onClick={()=>toggleFullScreenGraph(barChartRef?.current?.current)}
                            >
                                <MdFullscreen />
                                Во весь экран
                            </div>
                        </GraphContextMenu>,
                        window.document.body
                    )}

                    <label
                        htmlFor="graphsType"
                        className="graphs-type-switcher"
                        title='Сменить тип'
                    >
                        <MdSettingsSystemDaydream
                            className='graph-by-systems'
                            title='По системам'
                        />

                        <input
                            id="graphsType"
                            className="d-none"
                            type="checkbox"
                            onChange={e => setGraphsTypeIsRegions(e.currentTarget.checked)}
                            checked={graphsTypeIsRegions}
                        />

                        <MdPlace
                            className='graph-by-regions'
                            title='По регионам'
                        />
                    </label>
                </> :
                <LoadingBlock rounded />}
        </div>
    )
})

export default GraphsContainer;

type dataArrayProps = {
    hour: string,
    [key: string]: any
}

// Выбрать только уникальных значений одного параметра списка
const convertLineGraphData = (arr: dataArrayProps[]) => {
    let result: dataArrayProps[] = [];

    arr.forEach(elem => {
        let currdate = new Date(elem.hour);
        let currElem = { ...elem };
        currElem.hour = `${currdate.getHours()}:${("0" + currdate.getMinutes()).slice(-2)}`;
        result.push(currElem);
    });

    return result;
};

